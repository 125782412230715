import * as React from "react";
const SvgCompassHeading = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 140 144", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", xmlnsserif: "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 1.5
}, ...props }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(1,0,0,1,-51.6663,-3700.09)" }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(0.794927,0,0,0.794927,33.4097,768.072)" }, /* @__PURE__ */ React.createElement("circle", { cx: 112.855, cy: 3776.47, r: 63.094, style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: "8.81px"
} })), /* @__PURE__ */ React.createElement("g", { id: "Needle", style: {
  transformBox: "fill-box",
  transformOrigin: "center"
} }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(1,0,0,1,3.22958,-2.84217e-14)" }, /* @__PURE__ */ React.createElement("path", { d: "M118.596,3726.91L123.121,3770.09L114.071,3770.09L118.596,3726.91Z", style: {
  fill: "var(--chakra-colors-brand-200)"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(-1,1.22465e-16,-1.22465e-16,-1,240.422,7540.44)" }, /* @__PURE__ */ React.createElement("path", { d: "M118.596,3726.91L123.121,3770.09L114.071,3770.09L118.596,3726.91Z", style: {
  fill: "rgb(215,216,214)"
} }))), /* @__PURE__ */ React.createElement("g", { id: "Ordinals" }, /* @__PURE__ */ React.createElement("g", { id: "Tooltip-Top", serifid: "Tooltip Top", transform: "matrix(1.27689,0,0,1.2442,122.198,3712.47)" }, /* @__PURE__ */ React.createElement("text", { x: "-5.055px", y: "0px", style: {
  fontFamily: "'Helvetica'",
  fontSize: 14
} }, "N")), /* @__PURE__ */ React.createElement("g", { id: "Tooltip-Top1", serifid: "Tooltip Top", transform: "matrix(1.27689,0,0,1.2442,186.495,3776.77)" }, /* @__PURE__ */ React.createElement("text", { x: "-4.669px", y: "0px", style: {
  fontFamily: "'Helvetica'",
  fontSize: 14
} }, "E")), /* @__PURE__ */ React.createElement("g", { id: "Tooltip-Top2", serifid: "Tooltip Top", transform: "matrix(1.27689,0,0,1.2442,122.198,3843.7)" }, /* @__PURE__ */ React.createElement("text", { x: "-4.669px", y: "0px", style: {
  fontFamily: "'Helvetica'",
  fontSize: 14
} }, "S")), /* @__PURE__ */ React.createElement("g", { id: "Tooltip-Top3", serifid: "Tooltip Top", transform: "matrix(1.27689,0,0,1.2442,59.1774,3776.29)" }, /* @__PURE__ */ React.createElement("text", { x: "-6.607px", y: "0px", style: {
  fontFamily: "'Helvetica'",
  fontSize: 14
} }, "W")))));
export default SvgCompassHeading;
