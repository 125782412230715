import * as React from "react";
const SvgCompass = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 59 59", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", xmlnsserif: "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("path", { id: "compass", d: "M29.627,58.116c-15.821,0 -28.646,-12.825 -28.646,-28.646c-0,-15.822 12.825,-28.647 28.646,-28.647c15.822,-0 28.647,12.825 28.647,28.647c-0,15.821 -12.825,28.646 -28.647,28.646Zm0,-5.729c12.572,-0 22.918,-10.345 22.918,-22.917c-0,-12.572 -10.346,-22.918 -22.918,-22.918c-12.572,0 -22.917,10.346 -22.917,22.918c-0,12.572 10.345,22.917 22.917,22.917Zm12.891,-35.808l-7.161,18.62l-18.621,7.162l7.162,-18.621l18.62,-7.161Zm-12.891,15.755c1.572,0 2.865,-1.293 2.865,-2.864c-0,-1.572 -1.293,-2.865 -2.865,-2.865c-1.571,-0 -2.864,1.293 -2.864,2.865c-0,1.571 1.293,2.864 2.864,2.864Z", style: {
  fill: "currentColor",
  fillRule: "nonzero"
} }));
export default SvgCompass;
