import * as React from "react";
const SvgPins = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 24 44", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", xmlnsserif: "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("path", { id: "pins", d: "M0.23,43.357l22.907,-0l-0,-31.765c-0,-6.397 -5.132,-11.591 -11.453,-11.591c-6.322,0 -11.454,5.194 -11.454,11.591l0,31.765Zm11.436,-39.936c4.498,-0 8.151,3.696 8.151,8.248c-0,4.553 -3.653,8.249 -8.151,8.249c-4.499,-0 -8.151,-3.696 -8.151,-8.249c-0,-4.552 3.652,-8.248 8.151,-8.248Z", style: {
  fill: "currentColor"
} }));
export default SvgPins;
