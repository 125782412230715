import * as React from "react";
const SvgRadio = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 22 41", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", xmlnsserif: "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("path", { id: "radio", d: "M12.481,40.493l-2.453,-2.4c4.909,-4.347 8.011,-10.611 8.011,-17.6c-0,-6.989 -3.102,-13.251 -8.011,-17.599l2.453,-2.401c5.535,4.964 9.027,12.074 9.027,20c-0,7.926 -3.492,15.036 -9.027,20Zm-4.906,-4.8l-2.453,-2.401c3.656,-3.111 5.978,-7.685 5.978,-12.799c-0,-5.113 -2.322,-9.687 -5.978,-12.798l2.453,-2.401c4.282,3.73 6.994,9.148 6.994,15.199c0,6.051 -2.712,11.469 -6.994,15.2Zm-4.907,-4.801l-2.467,-2.414c2.409,-1.866 3.96,-4.745 3.96,-7.985c0,-3.239 -1.551,-6.119 -3.96,-7.984l2.467,-2.414c3.032,2.491 4.962,6.223 4.962,10.398c0,4.177 -1.93,7.91 -4.962,10.399Z", style: {
  fill: "currentColor"
} }));
export default SvgRadio;
