import * as React from "react";
const SvgMicrobitFaceIcon = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 28 23", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", xmlnsserif: "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("g", { id: "simple-microbit-image", serifid: "simple microbit image" }, /* @__PURE__ */ React.createElement("path", { d: "M27.931,4.112c0,-2.227 -1.808,-4.036 -4.036,-4.036l-19.859,0c-2.228,0 -4.036,1.809 -4.036,4.036l0,14.127c0,2.227 1.808,4.036 4.036,4.036c-0,-0 19.859,-0 19.859,-0c2.228,-0 4.036,-1.809 4.036,-4.036l0,-14.127Zm-2.667,12.672l0.042,-12.672c0,-0.779 -0.632,-1.411 -1.411,-1.411c0,0 -19.859,0 -19.859,0c-0.779,0 -1.411,0.632 -1.411,1.411l0,12.317l0.004,-0c0,0.921 0.749,1.67 1.671,1.67l19.332,-0c0.8,-0 1.469,-0.564 1.632,-1.315Z", style: {
  fill: "currentColor"
} }), /* @__PURE__ */ React.createElement("path", { d: "M22.423,10.272c0,-0.497 -0.403,-0.901 -0.9,-0.901l-2.726,0c-0.497,0 -0.9,0.404 -0.9,0.901l-0,2.725c-0,0.497 0.403,0.901 0.9,0.901l2.726,-0c0.497,-0 0.9,-0.404 0.9,-0.901l0,-2.725Zm-12.357,-0.109c0,-0.437 -0.354,-0.792 -0.791,-0.792l-2.943,0c-0.437,0 -0.792,0.355 -0.792,0.792l0,2.943c0,0.437 0.355,0.792 0.792,0.792l2.943,-0c0.437,-0 0.791,-0.355 0.791,-0.792l0,-2.943Z", style: {
  fill: "currentColor"
} }), /* @__PURE__ */ React.createElement("path", { d: "M2.625,2.701l10.64,0l-3.97,3.97l0,-3.97l-6.67,6.67l-0,-6.67Zm12.625,0l-1.985,1.985l0,-1.985l1.985,0Z", style: {
  fill: "currentColor"
} })));
export default SvgMicrobitFaceIcon;
