import * as React from "react";
const SvgButtonPress = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 57 63", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", xmlnsserif: "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("g", { id: "button" }, /* @__PURE__ */ React.createElement("path", { id: "Arrow-Right-Icon", serifid: "Arrow Right Icon", d: "M26.141,2.459l-0,16.375l-8.933,-8.941c-0.828,-0.827 -2.168,-0.827 -2.993,-0c-0.828,0.827 -0.828,2.168 -0.002,2.995l12.478,12.487c0.424,0.424 0.983,0.627 1.538,0.616c0.002,-0 0.002,0 0.003,0c0,0 0.001,-0 0.002,-0c0.555,0.011 1.114,-0.192 1.538,-0.616l12.477,-12.487c0.827,-0.827 0.827,-2.168 0,-2.995c-0.826,-0.827 -2.166,-0.827 -2.992,-0l-8.935,8.941l0,-16.375c0,-1.155 -0.936,-2.092 -2.09,-2.092c-1.155,0 -2.091,0.937 -2.091,2.092Z", style: {
  fill: "currentColor"
} }), /* @__PURE__ */ React.createElement("path", { d: "M56.276,41.528c0,-2.008 -1.63,-3.639 -3.638,-3.639l-48.84,0c-2.009,0 -3.639,1.631 -3.639,3.639l-0,17.733c-0,2.008 1.63,3.639 3.639,3.639l48.84,-0c2.008,-0 3.638,-1.631 3.638,-3.639l0,-17.733Z", style: {
  fill: "currentColor"
} }), /* @__PURE__ */ React.createElement("path", { d: "M16.158,36.7l0,-4.621c0,-1.753 1.423,-3.175 3.176,-3.175l17.767,-0c1.753,-0 3.176,1.422 3.176,3.175l-0,4.621l-24.119,-0Z", style: {
  fill: "currentColor"
} })));
export default SvgButtonPress;
