import * as React from "react";
const SvgAccelerometer = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 67 53", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", xmlnsserif: "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinecap: "square",
  strokeLinejoin: "round",
  strokeMiterlimit: 1.5
}, ...props }, /* @__PURE__ */ React.createElement("g", { id: "motion-13", serifid: "motion 13" }, /* @__PURE__ */ React.createElement("path", { d: "M6.771,23.351c-2.236,17.873 -0.895,19.924 18.735,24.328", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: "6.5px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M9.828,10.221l2.914,14.24l-12.557,-3.365l9.643,-10.875Z", style: {
  fill: "currentColor"
} }), /* @__PURE__ */ React.createElement("path", { d: "M56.648,41.635l-2.914,-14.24l12.557,3.365l-9.643,10.875Z", style: {
  fill: "currentColor"
} }), /* @__PURE__ */ React.createElement("path", { d: "M59.705,30.365c3.236,-18.873 -0.105,-20.672 -17.735,-25.075", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: "6.5px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M54.08,22.234c0.811,-3.029 -0.936,-6.133 -3.902,-6.928l-26.432,-7.082c-2.966,-0.795 -6.031,1.02 -6.843,4.048l-5.147,19.21c-0.811,3.028 0.936,6.133 3.901,6.928l26.433,7.082c2.965,0.795 6.031,-1.02 6.842,-4.049l5.148,-19.209Zm-8.167,16.28l4.673,-17.217c0.283,-1.059 -0.327,-2.144 -1.364,-2.421l-26.433,-7.083c-1.036,-0.278 -2.108,0.356 -2.392,1.415l-4.488,16.749l0.006,0.001c-0.336,1.253 0.388,2.538 1.615,2.867l25.731,6.895c1.065,0.285 2.161,-0.243 2.652,-1.206Z", style: {
  fill: "currentColor"
} }), /* @__PURE__ */ React.createElement("path", { d: "M20.615,11.083l20.717,5.551l-9.639,5.054l1.909,-7.125l-16.194,8.491l3.207,-11.971Zm24.581,6.587l-4.819,2.527l0.955,-3.563l3.864,1.036Z", style: {
  fill: "currentColor"
} })))));
export default SvgAccelerometer;
