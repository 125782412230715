import * as React from "react";
const SvgMicrophone = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 30 44", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", xmlnsserif: "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("path", { id: "microphone", d: "M11.84,35.624l-0,3.393l-7.249,0l0,3.988l21.159,0l0,-3.988l-7.248,0l-0,-3.391c6.366,-1.402 11.306,-6.749 11.306,-13.517l-4.785,0c-0,5.256 -4.606,9.092 -9.848,9.092c-5.245,0 -9.857,-3.835 -9.857,-9.092l-4.785,0c0,6.765 4.94,12.111 11.307,13.515Zm9.807,-28.232c0,-3.646 -2.96,-6.606 -6.603,-6.606c-3.648,0 -6.608,2.96 -6.608,6.606l0,12.517c0,3.65 2.96,6.606 6.608,6.606c3.643,-0 6.603,-2.956 6.603,-6.606l0,-12.517Z", style: {
  fill: "currentColor"
} }));
export default SvgMicrophone;
