import * as React from "react";
const SvgDataLogging = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 49 59", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", xmlnsserif: "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("g", { id: "data-file", serifid: "data file" }, /* @__PURE__ */ React.createElement("path", { id: "File-Icon", serifid: "File Icon", d: "M32.289,0.177l16.018,16.884l-16.018,-0l-0,-16.884Zm-4.577,14.473c-0,3.996 3.074,7.235 6.865,7.235l13.73,0l0,28.945c0,3.997 -3.073,7.237 -6.865,7.237l-34.326,-0c-1.895,-0 -3.514,-0.707 -4.854,-2.121c-1.34,-1.412 -2.011,-3.118 -2.011,-5.116l0,-43.417c0,-3.996 3.074,-7.236 6.865,-7.236l20.596,-0l-0,14.473Z", style: {
  fill: "currentColor"
} }), /* @__PURE__ */ React.createElement("path", { d: "M40.779,24.769l-32.027,0l-0,28.472l32.027,0l0,-28.472Zm-17.689,19.026l-11.59,-0l-0,5.981l11.59,0l0,-5.981Zm14.978,-0l-11.591,-0l0,5.981l11.591,0l-0,-5.981Zm-14.978,-7.927l-11.59,-0l-0,5.981l11.59,0l0,-5.981Zm14.978,-0l-11.591,-0l0,5.981l11.591,0l-0,-5.981Zm-14.978,-7.927l-11.59,-0l-0,5.981l11.59,0l0,-5.981Zm14.978,-0l-11.591,-0l0,5.981l11.591,0l-0,-5.981Z", style: {
  fill: "#fff"
} })));
export default SvgDataLogging;
